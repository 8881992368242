
.modal__backdrop2 {
	background: rgba(0, 0, 0, .65);
	bottom: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 9999;
}

.modal__container2 {
	background: #fff;
	border-radius: 5px;
	max-width: 100%;
	margin: 50px auto;
	padding: 15px;
	width: 560px;
}

.modal__title2 {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 25px;
}