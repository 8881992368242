/* Default Color File  */

.feature-block-one .icon,
.mobile-menu-visible .mobile-menu .menu-backdrop,
.instagram-widget .wrapper-box .image .overlay-link,
.news-block-one .category,
.contact-form i,
.testimonial-block .quote,
.single-product-item .price,
.single-product-item .rating span,
.team-block-one .inner-box .content .designation,
.default-video-box a:before,
.gallery-block-one .caption-title .view-project a,
.gallery-block-one .caption-title h5,
.gallery-block-one .caption-title h5:before,
.filters li.active,
.services-section-five .icon,
.services-section .author-box .designation,
.services-section .left-column,
.about-section .image-wrapper .image-two .caption,
.sec-title.text-center .sub-title:after,
.sec-title .sub-title:before,
.team-block-one .social-links li a:before,
.news-block-two .related-post .post-meta li a,
.news-block-two .related-post .post-meta li a i,
.progress-box .bar-inner,
.progress-box .count-text,
.sec-title .sub-title,
.career-block .time,
.preloader-close,
.scroll-to-top,
.sidebar-title:before,
.loan-calculator .calculate-btn button.active,
.project-post-pagination .page-view,
.portfolio-details .single-project-info .social-links li a:before,
.sidebar-title:after,
.blog-single-post blockquote h5,
.sidebar .popular-tags li a:before,
.sidebar .search-box .form-group button,
.social-links-widget .social-links li a:before,
.page-pagination li.active a,
.page-pagination li a:hover,
.news-block-two .category,
.news-block-three .inner-box:before,
.services-section-five .active .icon-box,
.feature-section-two .owl-theme .owl-nav .owl-next:hover:before,
.feature-section-two .owl-theme .owl-nav .owl-prev:hover:before,
.loan-calculator .calculation-value h4,
.counter-section,
.error-section .inner-section .text,
.banner-carousel .slide-item:before,
.testimonial-block-two .quote,
.service-block-one .icon,
.author-info .designation,
.cta-section .wrapper-box:before,
.testimonial-section-two .owl-theme .owl-nav .owl-next:hover:before,
.testimonial-section-two .owl-theme .owl-nav .owl-prev:hover:before,
.contact-section-two .icon-box .icon,
/* .main-footer .footer-bottom:before, */
/* .welcome-block-one .inner-box .icon-box, */
.insurance-calculate .wrapper-box,
.btn-style-one .btn-title,
.accordion-box .block .acc-btn.active,
.accordion-box .block .icon-outer .icon_minus,
.about-section-two .list-box li i,
.btn-style-two .btn-title:before,
.contact-section .icon-box .icon,
.main-footer .social-links li a:before,
.main-footer .logo-widget .widget-content:before,
.main-header .header-upper .inner-container,
.bootstrap-select .dropdown-menu li a.active,
.main-header.header-style-two .header-upper .logo-box .logo,
.shop-sidebar-wrapper .price-ranger .ranger-min-max-block input[type="submit"],
.service-block-one .icon .round-shape:before,
.testimonial-section.style-two:before,
.testimonial-section.style-three:before,
.case-block-one .overlay,
.error-search-form .form-group button,
.blog-single-post .tag-list li a:before,
.blog-post-pagination .page-view span,
.blog-post-pagination h5,
.overlay-style-one:before,
.news-block-four .category,
.comments-area .date,
.single-shop-area .price span,
.categories-widget-two ul li.current a,
.contact-form-widget,
.services-details blockquote:before,
.services-details blockquote h4:before,
.cart-area .cart-table tbody .available-info .icon,
.checkout-area .exisitng-customer h5 a,
.single-shop-area .top-content .review,
.sidebar-wrapper .price-ranger .ui-slider-handle,
.sidebar-wrapper .price-ranger .ui-slider .ui-slider-range,
.sidebar-wrapper .price-ranger .ui-slider-handle:before,
.history-section .tab-btn-style-one .nav-item .nav-link.active,
.product-tab-box .tab-btns .tab-btn.active-btn span,
.product-tab-box .tab-btns .tab-btn:hover span,
.related-product .owl-theme .owl-nav .owl-prev:hover:before,
.related-product .owl-theme .owl-nav .owl-next:hover:before,
.review-box-holder .single-review-box .text-holder .top .rating i,
.case-tabs .case-tab-btns .case-tab-btn:before,
.checkout-area .coupon h5 a,
.services-details blockquote h4,
.checkout-area .coupon:before,
.news-block-two .author-box-two h5,
.checkout-area .exisitng-customer:before,
.cases-section .owl-theme .owl-nav .owl-next:hover:before,
.cases-section .owl-theme .owl-nav .owl-prev:hover:before,
.testimonial-section .owl-theme .owl-nav .owl-next:hover:before,
.testimonial-section .owl-theme .owl-nav .owl-prev:hover:before,
.tab-btn-style-one .nav-item .nav-link:before,
.tab-btn-style-one .nav-item .nav-link.active,
.service-block-one .icon .round-shape:after,
.sticky-header .main-menu .navigation > li.current-menu-item > a {
  background: linear-gradient(90deg, rgba(0, 54, 164, 1) 0%, rgba(0, 126, 255, 1) 100%);
}

.welcome-block-one:nth-child(1) .inner-box:before,
.welcome-block-one:nth-child(3) .inner-box:before {
  background: linear-gradient(0deg, rgb(0, 0, 0, 1) 30%, rgb(112, 112, 112, 0.8) 100%);
}

.categories-widget-two ul li.current2 a {
  background: linear-gradient(90deg, rgb(53, 53, 53) 0%, rgb(121, 121, 121) 100%);
}

.welcome-block-one:nth-child(2) .inner-box:before {
  background: linear-gradient(0deg, rgba(0, 54, 164, 1) 3%, rgba(0, 126, 255, 0.8) 100%);
}

.btn-style-one .btn-title:before {
  background-color: #25283a;
}

.default-video-box a:hover,
.single-sidebar-box .categories li:hover a,
.banner-section.style-three .btn-style-one .btn-title:hover,
.banner-section.style-three .btn-style-two:hover .btn-title,
.banner-section.style-four .btn-style-one .btn-title:hover,
.banner-section.style-four .btn-style-two:hover .btn-title,
.insurance-calculate .btn-style-one .btn-title:hover,
.insurance-calculate .btn-style-two:hover .btn-title,
.header-top-two .top-right .social-links li a:hover,
.sidebar-wrapper .products-post li .title-holder .post-title a:hover,
.main-menu .navigation > li > ul > li > ul > li:hover > a,
.main-menu .navigation > li > ul > li:hover > a,
.sidebar-widget.about-me .social-links li a:hover,
.blog-single-post .post-share .social-links li a:hover,
.comments-area .comment-box .reply-btn:hover,
.news-block-two .read-more a,
.news-block-two h2 a:hover,
.news-block-one h3 a:hover,
.hidden-sidebar .news-widget h4 a:hover,
.sticky-header .main-menu .navigation > li:hover > a,
.sticky-header .main-menu .navigation > li.current > a,
.main-footer .links-widget ul li a:hover,
.testimonial-section.style-two .owl-theme .owl-nav .owl-prev:hover:before,
.testimonial-section.style-two .owl-theme .owl-nav .owl-next:hover:before,
.welcome-block-one:nth-child(1) .inner-box .read-more a:hover,
.welcome-block-one:nth-child(3) .inner-box .read-more a:hover {
  color: #003eae;
}

.welcome-block-one:nth-child(2) .inner-box .read-more a:hover {
  color: #222;
}

.contact-form i,
.author-info .designation,
.service-block-one .icon,
.feature-block-one .icon,
.progress-box .count-text,
.services-section-five .icon,
.contact-section .icon-box .icon,
.about-section-two .list-box li i,
.contact-section-two .icon-box .icon,
.single-shop-area .top-content .review,
.single-shop-area .price span,
.review-box-holder .single-review-box .text-holder .top .rating i,
.shop-sidebar-wrapper .price-ranger .ranger-min-max-block input[type="submit"],
.team-block-one .inner-box .content .designation,
.gallery-block-one .caption-title h5,
.checkout-area .exisitng-customer h5 a,
.checkout-area .coupon h5 a,
.accordion-box .block .acc-btn.active,
.accordion-box .block .icon-outer .icon_minus,
.blog-post-pagination h5,
.blog-post-pagination .page-view span,
.news-block-two .related-post .post-meta li a,
.news-block-two .related-post .post-meta li a i,
.loan-calculator .calculation-value h4,
.comments-area .date,
.tab-btn-style-one .nav-item .nav-link.active,
.services-details blockquote h4,
.news-block-two .author-box-two h5,
.blog-single-post blockquote h5,
.single-product-item .rating span,
.error-section .inner-section .text,
.services-section .author-box .designation,
.sec-title .sub-title {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
